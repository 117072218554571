import React from 'react'
import Layout from '../components/layout'
import SearchInput from '../components/SearchInput'
import Seo from "../components/seo"

const Russia = () => {
    return (
        <Layout>
            <Seo title="Russia" />

            <h1 className="title">Russia</h1>

            <SearchInput />

            <table>
                <tbody>
                    <tr>
                        <td>Federal Institute of Industrial Property search databases</td>
                        <td><a target="_blank" rel="noreferrer" href="https://new.fips.ru/iiss/db.xhtml">https://new.fips.ru/iiss/db.xhtml</a></td>
                    </tr>

                    <tr>
                        <td>State Register of Medicinal Products search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://grls.rosminzdrav.ru/GRLS.aspx">https://grls.rosminzdrav.ru/GRLS.aspx</a></td>
                    </tr>
                </tbody>
            </table>
        </Layout>
    )
}

export default Russia